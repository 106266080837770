/* --------------------------------------- */
/* STORE CONFIGURATION
/* --------------------------------------- */
const useMagentoCommerce = false; // setup uses magento commerce or community
const storeCode = ''; // fill it with any store code when the magento is setup with multiple stores. leave it empty to use default store.
const assetsVersion = '1.0.6';
const iconAppleTouch = '/assets/img/swiftpwa_apple_touch.png';
const primaryColor = '#BE1F93'; // default primary color used on the checkout page

const HOST = {
    local: 'http://localhost:3000',
    dev: '',
    stage: '',
    prod: '',
};

/* Magento GraphQL Endpoint */
const graphqlEndpoint = {
    local: 'https://scv2-bo.testingnow.me/graphql',
    dev: 'https://scv2-bo.testingnow.me/graphql',
    stage: 'https://scv2-bo-backend.hw-staging.testingnow.me/graphql',
    prod: 'https://backoffice.swift-checkout.com/graphql',
};

/* --------------------------------------- */
/* FEATURES CONFIGURATION
/* --------------------------------------- */

const installMessage = 'Get our free app.';
const appName = 'Swift APP';
const originName = 'pwa';

/* Social Sharing */
const shareIcon = {
    facebook: true,
    twitter: true,
    line: true,
    email: true,
    telegram: true,
    pinterest: false,
    linkedin: false,
};

/* Password Validator */
const passwordStrength = {
    minValue: 8,
    maxValue: 20,
    numberOfRequiredClass: 3, // Lowercase + Uppercse + Dgits + spesial caracter = 4
};

/* Translation */
const translation = {
    defaultLanguage: 'en', // just change to your default language
    languages: ['en', 'id'], // array code language what you want
    // language label code
    languagesLabel: {
        en: 'English',
        id: 'Indonesia',
    },
};

/* Google Tag Manager
 * before enable this configuration, firstly you need to import the gtm tags json.
 * gtm tags json need to be exported from Magento admin in Welpixel GTM configuration.
 * adjust the tag name if you want before import into GTM dashboard setting.
 * as reference you can find sample gtm tags in folder "sample/gtm" folder
 * NOTE: this GTM functionality includes connecting to GA via GTM tag.
 */
const GTM = {
    enable: false,
    gtmId: {
        local: '', // sample: GTM-N76V8KQ
        dev: '', // sample: GTM-N76V8KQ
        stage: '', // sample: GTM-N76V8KQ
        prod: '', // sample: GTM-N76V8KQ
    },
};

/* Recapthca Configuration */
const recaptcha = {
    enable: false,
    siteKey: {
        local: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        dev: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        stage: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
        prod: '', // sample: 6LcZmLEZAAAAADkdlp8S8tExis2EVhNiuMv6ayo7
    },
    serverKey: {
        local: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        dev: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        stage: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
        prod: '', // sample: 6LcZmLEZAAAAANHhkqwoRna2vqIVWHNKwOvxm26n
    },
};

// error management monitoring
const sentry = {
    enabled: false,
    enableMode: 'production',
    dsn: {
        local: 'https://c60fbed461fd49da9455730ba70da8a6@o484453.ingest.sentry.io/5537614',
        dev: 'https://c60fbed461fd49da9455730ba70da8a6@o484453.ingest.sentry.io/5537614',
        stage: 'https://c60fbed461fd49da9455730ba70da8a6@o484453.ingest.sentry.io/5537614',
        prod: 'https://c60fbed461fd49da9455730ba70da8a6@o484453.ingest.sentry.io/5537614',
    },
};

const rollbar = {
    enabled: false,
    config: {
        accessToken: '76876f52664341b4a1981c4618723bda',
        captureUncaught: true,
        captureUnhandledRejections: true,
    },
};

/* Contact Us */
// identifiers for cmsBlocks in contact page
const cmsContactIdentifiers = 'weltpixel_contact_page';

/* Dashboard */
// identifiers for cmsBlocks in contact page
const cmsSocialMediaLinkIdentifiers = 'pwa_socmed_links';

/* Social media link */
// social media link in dashboard
const enableSocialMediaLink = true;

/* Loader */
const loaderImage = '/assets/img/loader.svg';

/* --------------------------------------- */
/* LOCAD DATA CACHE & COKIES
/* --------------------------------------- */

const expiredCookies = 6;
const storeConfigNameCookie = 'storeConfig';
const nameCartId = 'nci';
const custDataNameCookie = 'cdt';
const nameCheckoutCookie = 'ccdt';
const nameGlobalCookie = 'spwa';
const nameToken = 'sk';
const expiredToken = new Date(Date.now() + 1000 * 60 * 60);
const expiredDefault = 365;
const localResolverKey = 'resolver';

const features = {
    useCustomStyle: false,
    ssrCache: false,
    customInstallApp: {
        enabled: true,
    },
    firebase: {
        config: {
            apiKey: '', // sample: AIzaSyCt9ks21BjiE9qirv-8xOEcUnUnH6viobw
            authDomain: '', // sample: swift-pwa-dev.firebaseapp.com
            databaseURL: '', // sample: https://swiftpwa-firebase.firebaseio.com
            projectId: '', // sample: swift-pwa-dev
            storageBucket: '', // sample: swift-pwa-dev.appspot.com
            messagingSenderId: '', // sample: 1083571104838
            appId: '', // sample: 1:1083571104838:web:4f06ca5a60b1c1a9efee47
            measurementId: '', // sample: G-HBHPY22H0H
        },
        pushNotification: {
            enabled: false,
            config: {
                // key from cloud messaging sertificat web push
                pairKey: '', // sample: BJ2IqpfQQGrckDUAI7TaX8r0_v6aykBSIIEpZUqhlkzZI2e7WVZk9ZB4xOiWBqTbVo6wk44gnpRLAJDemB66zAU
            },
        },
    },
    thumbor: {
        url: 'https://thumbor.sirclocdn.com/unsafe/widthxheight/filters:format(webp)/',
    },
};

const modules = {
    maintenance: {
        enabled: true,
        path: '/maintenance',
    },
    error: {
        enabled: true,
    },
};

const nossrCache = [
    '/',
    '/profile',
    '/dashboard',
    '/ecpconfig',
    '/projects',
    '/graphql',
    '/authentication',
];

const debuging = {
    originalError: false,
};

const general = {
    defaultCurrencyCode: 'IDR',
};

const keyCookies = {
    loginData: 'scvbo_infologin',
    token: 'scvbo_authtoken',
    profileName: 'scvbo_profileName',
    defaultBrandId: 'default_brand_id',
};

const gmapKey = 'AIzaSyBkt1f5BCCFPCbh9Xl3ynhEO5Ng_2DxCh0';

const bankList = ['BCA', 'BNI', 'BRI', 'MANDIRI', 'Permata', 'CIMB', 'Others'];

module.exports = {
    assetsVersion,
    iconAppleTouch,
    general,
    sentry,
    storeCode,
    debuging,
    GTM,
    HOST,
    graphqlEndpoint,
    shareIcon,
    passwordStrength,
    expiredCookies,
    storeConfigNameCookie,
    nameCartId,
    nameToken,
    expiredToken,
    expiredDefault,
    loaderImage,
    cmsContactIdentifiers,
    cmsSocialMediaLinkIdentifiers,
    custDataNameCookie,
    nameCheckoutCookie,
    nameGlobalCookie,
    enableSocialMediaLink,
    features,
    nossrCache,
    recaptcha,
    modules,
    installMessage,
    appName,
    localResolverKey,
    originName,
    useMagentoCommerce,
    rollbar,
    translation,
    keyCookies,
    gmapKey,
    bankList,
    primaryColor,
};
